import styled from "styled-components"
import { respond } from "../_respond"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const Container = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 5rem auto;
  max-width: 780px;
  font-size: 1.125rem;
  line-height: 1.89;
  text-align: left;

  ${respond.mobile`
  padding: 0 1.2rem;
  `}
`

export default withSimpleErrorBoundary(Container)
